import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Card, InputGroup, FormControl, Button } from "react-bootstrap";
import { AuthStore, OrbitalStore, Tooltip } from "orbital_common_components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faToggleOff, faInfinity, faExclamationTriangle, faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import moment from "moment";
import _ from "lodash";

import * as LoginUtilsV2 from "@services/LoginUtilsV2";
import * as Utils from "@services/Utils";
import localization from "@services/localization";

import Settings from "@views/header/Settings";

import LoadingOverlay from "@views/newCommonComponents/LoadingOverlay";
import { useNavigate } from "react-router-dom";

export default function UserList() {
    const navigate = useNavigate();

    const orbitalLogo = Utils.getStaticOrbitalLogo();
    const orbitalConfig = OrbitalStore.getOrbitalConfig() || {};
    const { imageMiniLogo } = orbitalConfig;
    const orbitalId = _.cloneDeep(AuthStore.getAuth());
    const { lastName, firstName, linkedUsers } = orbitalId;

    const [loading, setLoading] = useState(null);
    const [filter, setFilter] = useState(null);
    const [filteredUsers, setFilteredUsers] = useState([]);

    /*************************************************************************/
    /************************ STANDARD ***************************************/
    /*************************************************************************/
    useEffect(() => {
        var users = linkedUsers;
        users = _.map(users, (user) => {
            if (_.isEmpty(user._brand) === false) {
                const brand = user._brand;
                user._brandName = brand.name;
                return user;
            } else {
                console.error("User without valid brandId: ", user.id)
            }
        })

        users = _.compact(users);
        if (_.isEmpty(filter) === false) {
            const lowerFilter = filter.toLowerCase();
            users = _.filter(users, (user) => {
                const brand = user._brand;
                const tmp = (user.nickname + brand.name).toLowerCase();
                const idx = tmp.indexOf(lowerFilter);
                if (idx !== -1) {
                    return user;
                }
            })
        }
        users = _.sortBy(users, ["_brandName", "role"]);
        setFilteredUsers(users);
    }, [filter])

    /*************************************************************************/
    /************************ FUNCTIONS **************************************/
    /*************************************************************************/
    function userLogin(user) {
        const selectedUser = _.cloneDeep(user);

        setLoading(true);
        LoginUtilsV2.userLogin(selectedUser)
            .then(() => {
                navigate("/dashboardUser");
            })
            .catch((error) => {
                console.error(error);
                toast.error(localization.errorLoginUser || "Error during user login");
                setLoading(false);
            })
    }

    /*************************************************************************/
    /************************** RENDER ***************************************/
    /*************************************************************************/
    function validateOwnerLicense(user) {
        const { role, _ownerLicense } = user;
        const { endDate } = _ownerLicense || {};

        var validLicense = true;
        if (role === "Owner" && _.isEmpty(_ownerLicense) === true) {
            validLicense = true;
        } else if (role === "Owner" && _ownerLicense.unlimited !== true && _ownerLicense.endDate) {
            var parsedEndDate = moment(endDate, "DD/MM/YYYY").endOf('day');
            var now = moment();
            if (now.isAfter(parsedEndDate)) {
                validLicense = false;
            }
        }
        return validLicense;
    }

    function parseLicense(user, isLicenseValid) {
        const { role, _ownerLicense } = user;
        const { unlimited, startDate, endDate } = _ownerLicense || {};

        if (role === "Owner" && _.isEmpty(_ownerLicense) === true) {
            return <div><FontAwesomeIcon className={"license_expired_icon"} icon={faExclamationTriangle} />{" "}{localization.systemAdminToValidateLicense || "Contact the system admin to validate the owner license"}</div>;
        } else if (role === "Owner" && unlimited === true) {
            return <div>{localization.license || "License"}: <span><Tooltip tooltip={localization.unlimited || "Unlimited"}><FontAwesomeIcon icon={faInfinity} /></Tooltip></span></div>
        } else if (role === "Owner" && unlimited !== true) {
            return <div>
                {localization.license || "License"}: {startDate} - {endDate}{"    "}
                <span hidden={isLicenseValid == true}><Tooltip tooltip={localization.licenseExpired || "License expired"}><FontAwesomeIcon className={"license_expired_icon"} icon={faExclamationTriangle} /></Tooltip></span>
            </div>
        }
    }

    return (
        <Row className="general_background dahsboard_users_container">
            <Col xs={{ span: 12, offset: 0 }} sm={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} xl={{ span: 10, offset: 1 }}>
                <Row>
                    <Col xs={{ span: 8, offset: 1 }} sm={{ span: 10, offset: 0 }} lg={{ span: 10, offset: 0 }} xl={{ span: 11, offset: 0 }}>
                        <img height="100px" alt="" className="header_logo" src={orbitalLogo} />
                    </Col>
                    <Col xs={{ span: 2, offset: 0 }} sm={{ span: 2, offset: 0 }} lg={{ span: 2, offset: 0 }} xl={{ span: 1, offset: 0 }}>
                        <Settings loading={loading} currentView={"orbitalDashboard"}></Settings>
                    </Col>
                </Row>
                <Row className="margin_top_row">
                    <Col xs={{ span: 11, offset: 1 }} sm={{ span: 12, offset: 0 }}>
                        <h2>
                            {localization.welcome || "Welcome"}{","} {lastName} {firstName}
                        </h2>
                    </Col>
                </Row>
                <Row className="user_container">
                    <Col xs={{ span: 10, offset: 1 }} sm={{ span: 10, offset: 1 }} md={{ span: 10, offset: 1 }} lg={{ span: 10, offset: 1 }} xl={{ span: 6, offset: 3 }} >
                        <Card>
                            <LoadingOverlay active={loading} spinner text={(localization.loading || "Loading") + "..."}>
                                <Card.Body>
                                    <Row>
                                        <Col xs={4} sm={5} md={4} lg={4} xl={4}>
                                            <h3>{localization.users || "Users"}</h3>
                                        </Col>
                                        <Col xs={{ span: 8 }} sm={{ span: 6, offset: 1 }} md={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }} xl={{ span: 5, offset: 3 }}>
                                            <InputGroup>
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text>
                                                        <FontAwesomeIcon icon={faSearch} />
                                                    </InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <FormControl
                                                    placeholder={localization.search || "Search"}
                                                    value={filter || ""}
                                                    className="search_input"
                                                    onChange={e => { setFilter(e.target.value) }}>
                                                </FormControl>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <div className="user_tab">
                                        {_.map(filteredUsers, (user, index) => {
                                            const brand = user._brand;
                                            const brandActive = brand && brand.active;
                                            const isLicenseValid = validateOwnerLicense(user);
                                            const parsedLicense = parseLicense(user, isLicenseValid);
                                            const loginUserAllowed = brandActive == true && isLicenseValid == true;

                                            const rowClassname = (filteredUsers.length - 1) == index ? "last_user_row" : "user_row";
                                            return (
                                                <Row className={rowClassname} key={index} style={{ backgroundColor: "lightred" }}>
                                                    <Col className="d-none d-md-block" sm={2} md={3} lg={3} xl={2}>
                                                        <img className="user_brand_logo" alt="" src={brand.brandLogo || imageMiniLogo}></img>
                                                    </Col>
                                                    <Col xs={9} sm={9} md={7} lg={7} xl={8}>
                                                        <div>
                                                            {brand.name}{" "}
                                                            <span hidden={brandActive == true}>
                                                                <Tooltip tooltip={localization.brandNotActive || "Brand not active"}><FontAwesomeIcon className="not_active_brand" icon={faToggleOff} /></Tooltip>
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="user_nickname">{user.nickname} - {user.subRole || user.role}</span>
                                                        </div>
                                                        {user.role == "Owner" &&
                                                            <div className="owner_license">{parsedLicense}</div>
                                                        }
                                                        {user.role == "Brand Manager" &&
                                                            <div className="fake_brand_license">ggg</div>
                                                        }
                                                    </Col>
                                                    <Col className="enter_col" xs={3} sm={3} md={2} lg={2} xl={2}>
                                                        <div style={{ color: "transparent" }}>.</div>
                                                        <Button disabled={!loginUserAllowed} size="sm" variant={loginUserAllowed ? "outline-primary" : "outline-secondary"} onClick={() => { userLogin(user) }}>
                                                            <FontAwesomeIcon icon={faSignInAlt}></FontAwesomeIcon>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            )
                                        })}
                                    </div>
                                </Card.Body>
                            </LoadingOverlay>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}