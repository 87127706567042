import { APISb as API_SB } from "orbital_common_components";
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const hash = require('object-hash');

const SBAPI_URL = process.env.REACT_APP_SBAPI_URL;
const CONFIG_KEY = process.env.REACT_APP_CONFIG_KEY || "D001";
const AUTHUSER = process.env.REACT_APP_ORBITAL_DASHBOARD_USER;
const AUTHPWD = process.env.REACT_APP_ORBITAL_DASHBOARD_PASSWORD;

export default class SpecificAPI {
    static SBDashboardAuthkey = null;

    /*************************************************************************/
    /******************************* AGREEMENTS ******************************/
    /*************************************************************************/
    static getTermsAndConditions() {
        return new Promise(function (resolve, reject) {
            var url = "/ConfigMo/getOrbitalAgreement";

            API_SB.get_plain(SBAPI_URL, url, null, null)
                .then((result) => {
                    var data = result.data;
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }

    static getPrivacyPolicy() {
        return new Promise(function (resolve, reject) {
            var url = "/ConfigMo/getOrbitalPrivacy";

            API_SB.get_plain(SBAPI_URL, url, null, null)
                .then((result) => {
                    var data = result.data;
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }

    /*************************************************************************/
    /******************************* LOGIN V2*********************************/
    /*************************************************************************/
    static cleanOrbitalId(orbitalId) {
        var self = this;
        return new Promise(function (resolve, reject) {
            var url = "/system/cleanOrbitalId";
            var params = { "uuid": orbitalId }

            API_SB.post(self.SBDashboardAuthkey, SBAPI_URL, url, params, null)
                .then((result) => {
                    var data = result.data;
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }

    /*************************************************************************/
    /******************************* LOGIN V2*********************************/
    /*************************************************************************/
    static adminLogin(username, password) {
        return new Promise(function (resolve, reject) {
            var url = "/system/login";

            var bodyFormData = new FormData();
            bodyFormData.append('grant_type', "password");
            bodyFormData.append('username', username);
            bodyFormData.append('password', password);
            bodyFormData.append('devicename', "system");
            bodyFormData.append('devicetype', "dashboard");
            bodyFormData.append('fingerprint', "none");


            API_SB.post_plain(SBAPI_URL, url, null, bodyFormData)
                .then((result) => {
                    var data = result.data || {};
                    resolve(data);
                })
                .catch((error) => {
                    console.error("Error in SpringBoot Dashboard Login");
                    reject(error);
                })
        })
    }

    static orbitalIdLogin(username, password) {
        return new Promise(function (resolve, reject) {
            const fpPromise = FingerprintJS.load()
            fpPromise
                .then((data) => {
                    return data.get();
                })
                .then((data) => {
                    var url = "/orbital/login";

                    var visitorId = data.visitorId;
                    visitorId = visitorId + username;
                    visitorId = hash(visitorId);

                    var bodyFormData = new FormData();
                    bodyFormData.append('grant_type', "password");
                    bodyFormData.append('username', username);
                    bodyFormData.append('password', password);
                    bodyFormData.append('devicename', "chrome");
                    bodyFormData.append('devicetype', "dashboard");
                    bodyFormData.append('fingerprint', visitorId);

                    API_SB.post_plain(SBAPI_URL, url, null, bodyFormData)
                        .then((result) => {
                            var data = result.data || {};
                            resolve(data);
                        })
                        .catch((error) => {
                            console.error("Error in SpringBoot Dashboard Login");
                            reject(error);
                        })
                })
                .catch((error) => {
                    console.error("Error in SpringBoot Dashboard Login");
                    reject(error);
                })
        })
    }

    /*************************************************************************/
    /************************ POSTGRES ORBITAL ID TABLE***********************/
    /*************************************************************************/
    static getPostgresOrbitalIdById() {
        var self = this;
        return new Promise(function (resolve, reject) {
            var url = "/OrbitalidPs/whoAmI";

            API_SB.get(self.SBDashboardAuthkey, SBAPI_URL, url, null)
                .then((result) => {
                    var data = result.data;
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }

    // static updatePostgresOrbitalIdById(orbitalId) {
    //     var self = this;
    //     return new Promise(function (resolve, reject) {
    //         var id = orbitalId.id;
    //         var url = "/OrbitalidPs/" + id;
    //         API_SB.put(self.SBDashboardAuthkey, SBAPI_URL, url, null, orbitalId)
    //             .then((result) => {
    //                 var data = result.data;
    //                 resolve(data);
    //             })
    //             .catch((error) => {
    //                 reject(error);
    //             })
    //     })
    // }

    static updatePostgresOrbitalIdById(orbitalId) {
        var self = this;
        return new Promise(function (resolve, reject) {
            var id = orbitalId.uuid;
            var url = "/OrbitalidPs/services/" + id;
            API_SB.put(self.SBDashboardAuthkey, SBAPI_URL, url, null, orbitalId)
                .then((result) => {
                    var data = result.data;
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }

    /*************************************************************************/
    /************************ MONGO ORBITAL ID TABLE *************************/
    /*************************************************************************/
    static sendResetPasswordLink(email) {
        return new Promise(function (resolve, reject) {
            var url = "/OrbitalIdMo/sendPasswordResetLink";
            var params = { "email": email };
            API_SB.get_plain(SBAPI_URL, url, params)
                .then(result => {
                    var data = result.data;
                    resolve(data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    static resetPassword(email, password, resetToken) {
        return new Promise(function (resolve, reject) {
            var url = "/OrbitalIdMo/resetPassword";
            var data = { email, password, resetToken };

            API_SB.post_plain(SBAPI_URL, url, null, data)
                .then(result => {
                    var data = result.data;
                    resolve(data);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }

    static getOrbitalIdByEmail(email) {
        var self = this;
        return new Promise(function (resolve, reject) {
            var url = "/OrbitalIdMo/search/findByEmail";
            var params = { "email": email }

            API_SB.get(self.SBDashboardAuthkey, SBAPI_URL, url, params)
                .then((result) => {
                    var data = result.data;
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }

    static getMongoOrbitalIdById(orbitalId) {
        var self = this;
        return new Promise(function (resolve, reject) {
            var url = "/OrbitalIdMo/" + orbitalId;

            API_SB.get(self.SBDashboardAuthkey, SBAPI_URL, url, null)
                .then((result) => {
                    var data = result.data;
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }

    static updateMongoOrbitalId(orbitalId) {
        var self = this;
        return new Promise(function (resolve, reject) {
            var id = orbitalId.id;
            var url = "/OrbitalIdMo/" + id;
            API_SB.put(self.SBDashboardAuthkey, SBAPI_URL, url, null, orbitalId)
                .then((result) => {
                    var data = result.data;
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }

    /*************************************************************************/
    /************************ USER *******************************************/
    /*************************************************************************/
    static getUsersByOrbitalId(orbitalId) {
        var self = this;
        return new Promise(function (resolve, reject) {
            var url = "/UserMo/search/findByOrbitalId";
            var params = { "orbitalId": orbitalId };

            API_SB.get(self.SBDashboardAuthkey, SBAPI_URL, url, params)
                .then((result) => {
                    var data = result.data;
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }

    /*************************************************************************/
    /************************ CONFIG *****************************************/
    /*************************************************************************/
    static getOrbitalConfig() {
        var self = this;
        return new Promise(function (resolve, reject) {
            var url = "/ConfigMo/search/findByKey";
            var params = { "key": CONFIG_KEY };

            API_SB.get(self.SBDashboardAuthkey, SBAPI_URL, url, params)
                .then((result) => {
                    var data = result.data;
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }

    /*************************************************************************/
    /************************ PERMISSION *************************************/
    /*************************************************************************/
    static getPluginPermissionsByUserId(userId) {
        var self = this;
        return new Promise(function (resolve, reject) {
            var url = "/PluginPermissionMo/search/findByUserId";
            var params = { "userId": userId };

            API_SB.get(self.SBDashboardAuthkey, SBAPI_URL, url, params)
                .then((result) => {
                    var data = result.data;
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }

    static getBrandPermissionGroups(brandId) {
        var self = this;
        return new Promise(function (resolve, reject) {
            var url = "/BrandConfigurationMo/pluginPermissionGroup";
            var params = { "brandId": brandId };

            API_SB.get(self.SBDashboardAuthkey, SBAPI_URL, url, params)
                .then((result) => {
                    var data = result.data;
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }

    /*************************************************************************/
    /************************ BRAND CONFIG ***********************************/
    /*************************************************************************/
    static getBrandConfigByBrandId(brandId) {
        var self = this;
        return new Promise(function (resolve, reject) {
            var url = "/BrandConfigurationMo/search/findByBrandId";
            var params = { "brandId": brandId };

            API_SB.get(self.SBDashboardAuthkey, SBAPI_URL, url, params)
                .then((result) => {
                    var data = result.data;
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }

    /*************************************************************************/
    /************************ ACTIVE PLUGINS *********************************/
    /*************************************************************************/
    static getActivePluginsByUserId(userId) {
        var self = this;
        return new Promise(function (resolve, reject) {
            var url = "/PluginActivationMo/search/findActivePluginsByUserId";
            var params = { "userId": userId };

            API_SB.get(self.SBDashboardAuthkey, SBAPI_URL, url, params)
                .then((result) => {
                    var data = result.data;
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }

    /*************************************************************************/
    /************************* PAYMENT CONFIG ********************************/
    /*************************************************************************/
    static getBrandPaymentConfig(brandId) {
        var self = this;
        return new Promise(function (resolve, reject) {
            var url = "/BrandPaymentConfigMo/brand/" + brandId;
            API_SB.get(self.SBDashboardAuthkey, SBAPI_URL, url, null)
                .then((result) => {
                    var data = result.data || {};
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }

    static getActivityPaymentConfig(activityId) {
        var self = this;
        return new Promise(function (resolve, reject) {
            var url = "/ActivityPaymentConfigMo/activity/" + activityId;
            API_SB.get(self.SBDashboardAuthkey, SBAPI_URL, url, null)
                .then((result) => {
                    var data = result.data || {};
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                })
        })
    }

    /*************************************************************************/
    /************************ Announcement ***********************************/
    /*************************************************************************/
    static getAnnouncementsByTarget(role) {
        var target = "";
        switch (role) {
            case "Brand Manager":
                target = "brand"
                break;
            case "Owner":
                target = "owner"
                break;
            default:
                target = null;
                break;
        }

        return new Promise((resolve, reject) => {
            var url = "/AnnouncementMo/findByTarget";
            var params = { target: target };
            API_SB.get(this.SBDashboardAuthkey, SBAPI_URL, url, params)
                .then(result => {
                    var data = result.data;
                    resolve(data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
}