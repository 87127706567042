import React, { useEffect, useState, useContext } from "react";
import { DropdownButton, Dropdown, Modal } from "react-bootstrap";
import { AuthStore, OrbitalStore, SessionStorageStore, CommonUtils } from "orbital_common_components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faSignOutAlt, faCheck } from "@fortawesome/free-solid-svg-icons";
import { faUser, faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import _ from "lodash";

import localization from "@services/localization";
import { AppContext } from "@services/AppContext";

import OrbitalId from "@views/commonComponents/OrbitalId";
import Agreements from "@views/commonComponents/Agreements";
import { useNavigate } from "react-router-dom";

export default function Settings(props) {
    const navigate = useNavigate();
    
    const { loading, currentView } = props;

    const containerVersion = process.env.REACT_APP_PLUGIN_VERSION;
    const orbitalConfig = OrbitalStore.getOrbitalConfig();

    const [currentLang, setCurrentLang] = useState(SessionStorageStore.getCurrentLang() || AuthStore.getDefautlLang());
    const [orbitalId, setOrbitalId] = useState(_.cloneDeep(AuthStore.getAuth()));
    const [pluginVersion, setPluginVersion] = useState("--");

    const [showOrbitalIdModal, setShowOrbitalIdModal] = useState(false);
    const [forceOrbitalIdModal, setForceOrbitalIdModal] = useState(false);
    const [showAgreementsModal, setShowAgreementsModal] = useState(false);
    const [agreementType, setAgreementType] = useState(null);
    const [forceAgreementsModal, setForceAgreementsModal] = useState(null);

    /*************************************************************************/
    /***************************** RENDER ************************************/
    /*************************************************************************/
    useEffect(() => {
        const channel = CommonUtils.getPluginVersionChannel();
        window.addEventListener(channel, function (event) {
            const pluginVersion = event.pluginVersion;
            setPluginVersion(pluginVersion);
        })

        loadOrbitalId();
    }, [])

    /*************************************************************************/
    /***************************** FUNCTIONS *********************************/
    /*************************************************************************/
    function evaluateOrbitalId(orbitalId) {
        var forceOrbitalIdModal = false;
        const { city, country, firstName, lastName, lang, phoneNo, postalCode } = orbitalId;

        if (_.isEmpty(firstName) || firstName === "oooooooooo") {
            orbitalId.firstName = null;
            forceOrbitalIdModal = true;
        }
        if (_.isEmpty(lastName) || lastName === "oooooooooo") {
            orbitalId.lastName = null;
            forceOrbitalIdModal = true;
        }
        if (_.isEmpty(phoneNo) || phoneNo === "+999999999999") {
            orbitalId.phoneNo = null;
            forceOrbitalIdModal = true;
        }
        if (_.isEmpty(city) || city === "city") {
            orbitalId.city = null;
            forceOrbitalIdModal = true;
        }
        if (_.isEmpty(country)) {
            orbitalId.country = null;
            forceOrbitalIdModal = true;
        }
        if (_.isEmpty(postalCode) || postalCode === "00000") {
            orbitalId.postalCode = null;
            forceOrbitalIdModal = true;
        }
        if (_.isEmpty(lang)) {
            orbitalId.lang = null;
            forceOrbitalIdModal = true;
        }

        return forceOrbitalIdModal;
    }

    function loadOrbitalId() {
        const orbitalId = _.cloneDeep(AuthStore.getAuth());
        const forceOrbitalIdModal = evaluateOrbitalId(orbitalId);

        const _postgresOrbitalId = orbitalId._postgresOrbitalId || {};

        const { orbitalAgreement, privacyPolicyAgreement } = _postgresOrbitalId;
        const forceAgreementsModal = _.isEmpty(orbitalAgreement) === true || _.isEmpty(privacyPolicyAgreement) === true;

        delete orbitalId["linkedUsers"];
        delete orbitalId["_postgresOrbitalId"];

        setOrbitalId(orbitalId);
        setForceOrbitalIdModal(forceOrbitalIdModal);
        setForceAgreementsModal(forceAgreementsModal);
    }

    function setLang(lang) {
        SessionStorageStore.setCurrentLang(lang)
            .then((lang) => {
                setCurrentLang(lang);
                // updateLocalizationLanguage(lang);
            })
            .catch((error) => {
                console.error(error);
            })
    }

    function logout() {
        const auth = AuthStore.getAuth();
        const user = auth.user || {};
        const userRole = user.role;

        if (currentView === "orbitalDashboard" || userRole === "super-admin") {
            SessionStorageStore.removeAuth();
            SessionStorageStore.removeDashboardAuthKey();
            SessionStorageStore.removeUserAuthKey();
            SessionStorageStore.removeCurrentLang();
            sessionStorage.removeItem("pluginKey");
            AuthStore.setAuth({});
            navigate("/login");
        } else if (currentView === "defaultLayout") {
            delete auth["user"]
            SessionStorageStore.setAuth(auth);
            AuthStore.setAuth(auth);
            navigate("/users");
        }
    }

    /*************************************************************************/
    /***************************** RENDER ************************************/
    /*************************************************************************/
    function parseHeader() {
        const { lastName, firstName, user } = orbitalId || {};

        if (currentView === "orbitalDashboard") {
            return <center><div>{lastName} {firstName}</div></center>
        } else if (currentView === "defaultLayout") {
            return <center>
                <div>{lastName} {firstName}</div>
                {user && user.nickname &&
                    <div className="settings_nickname">({user.nickname})</div>
                }
            </center>
        }
    }

    function parseLanguages() {
        const auth = AuthStore.getAuth() || {};
        const user = auth.user || {};

        var languages = null;
        if (currentView === "orbitalDashboard" || user.role === "super-admin") {
            languages = orbitalConfig.preferedlang;
            languages = _.map(languages, (lang, index) => {
                const label = currentLang !== lang.value ? lang.value : <span>{lang.value}{" "}<FontAwesomeIcon icon={faCheck} /></span>
                return <Dropdown.Item onClick={() => { setLang(lang.value); }} key={index} eventKey={lang.value}>{label}</Dropdown.Item>
            })
            return languages;
        } else if (currentView === "defaultLayout") {
            const brand = user._brand || {};
            const brandConfig = brand._brandConfiguration || {};
            const preferedLang = brandConfig.preferedLang || [];
            languages = _.map(preferedLang, (lang, index) => {
                const label = currentLang !== lang ? lang : <span>{lang}{" "}<FontAwesomeIcon icon={faCheck} /></span>
                return <Dropdown.Item onClick={() => { setLang(lang) }} key={index} eventKey={lang}>{label}</Dropdown.Item>
            })
        }
        return languages;
    }

    return (
        <React.Fragment>
            <DropdownButton disabled={loading === true} align="start" variant="outline-secondary" title={<FontAwesomeIcon icon={faCog} />}>
                <Dropdown.Header>{parseHeader()}</Dropdown.Header>
                <Dropdown.Item onClick={() => { setShowOrbitalIdModal(true) }}><FontAwesomeIcon icon={faUser} />{" "}{localization.profile || "Profile"}</Dropdown.Item>
                <Dropdown.Item onClick={() => {
                    setShowAgreementsModal(true);
                    setAgreementType("termsAndConditions");
                }}>
                    <FontAwesomeIcon icon={faQuestionCircle} />{" "}{localization.termsAndConditions || "Terms & Conditions"}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => {
                    setShowAgreementsModal(true);
                    setAgreementType("privacyPolicy");
                }}>
                    <FontAwesomeIcon icon={faQuestionCircle} />{" "}{localization.privacyPolicy || "Privacy Policy"}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Header>{localization.languages || "Languages"}</Dropdown.Header>
                {parseLanguages()}
                <Dropdown.Divider />
                <Dropdown.Header>{localization.versions || "Versions"}</Dropdown.Header>
                <Dropdown.Item disabled={true}><span className="versioning">{localization.container || "Container"}: {containerVersion}</span></Dropdown.Item>
                <Dropdown.Item disabled={true}><span className="versioning">{localization.plugin || "Plugin"}: {pluginVersion || "--"}</span></Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={logout} eventKey="4"><FontAwesomeIcon icon={faSignOutAlt} />{" "}{localization.logout || "Logout"}</Dropdown.Item>

            </DropdownButton>
            <Modal show={showOrbitalIdModal || forceOrbitalIdModal}>
                <OrbitalId
                    selectedUser={orbitalId}
                    hideCancelButton={forceOrbitalIdModal}
                    onSave={loadOrbitalId}
                    onCancel={() => {
                        setShowOrbitalIdModal(false);
                        setForceOrbitalIdModal(false);
                    }}>
                </OrbitalId>
            </Modal>
            <Modal size="lg" show={showAgreementsModal || forceAgreementsModal}>
                <Agreements
                    agreementType={agreementType}
                    hideCancelButton={forceAgreementsModal}
                    showAcceptButton={forceAgreementsModal}
                    onCancel={() => {
                        setShowAgreementsModal(false);
                        setForceAgreementsModal(false);
                        setAgreementType(null);
                    }}>
                </Agreements>
            </Modal>
        </React.Fragment>
    )
}