import React, { useState, useEffect } from "react";
import { AuthStore } from "orbital_common_components";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import _ from "lodash";

import SpecificAPI from "@services/SpecificAPI";
import localization from "@services/localization";

import { SettingBtnDiv, AnnouncementBtnDiv, WelcomeDiv, DefaultLayoutHeader, CollapseButton } from "@views/header/HeaderStyledComponents";
// import brandPluginRoutes from "@views/plugins/brandPluginRoutes";
// import ownerPluginRoutes from "@views/plugins/ownerPluginRoutes";
import brandPluginRoutes from "@views/plugins/brand/brandPluginRoutes";
import ownerPluginRoutes from "@views/plugins/owner/ownerPluginRoutes";
import Sidebar from "@views/dashboard/Sidebar";
import Settings from "@views/header/Settings";
import Announcements from "@views/header/Announcements";

import LoadingOverlay from "@views/newCommonComponents/LoadingOverlay";

export default function DashboardUser() {
    const routes = _.concat(brandPluginRoutes, ownerPluginRoutes);

    const auth = AuthStore.getAuth();
    const user = auth.user;

    const [loading, setLoading] = useState(true);
    const [collapsed, setCollapsed] = useState(false);
    const [allowedRoutes, setAllowedRoutes] = useState([]);

    /*************************************************************************/
    /*************************** STANDARD ************************************/
    /*************************************************************************/
    useEffect(() => {
        const userId = auth.user.id;
        const defaultLang = auth.lang;
        const pluginPermissions = auth.user._permission || [];

        SpecificAPI.getActivePluginsByUserId(userId)
            .then((activePlugins) => {
                // console.error(activePlugins)

                const allowedRoutes = [];
                _.each(activePlugins, (activePlugin) => {
                    var pluginKey = activePlugin.pluginKey;

                    //TODO: remove the "R"
                    const permission = pluginPermissions[pluginKey] || "R";
                    const route = _.find(routes, { "pluginKey": pluginKey });
                    if (_.isEmpty(permission) === false && permission !== "D" && _.isEmpty(route) === false) {
                        var pluginLabel = activePlugin.label && typeof activePlugin.label === "string" ? JSON.parse(activePlugin.label) : {};
                        pluginLabel = pluginLabel[defaultLang] || route.name;

                        const newRoute = _.cloneDeep(route);
                        newRoute.name = pluginLabel;

                        allowedRoutes.push(newRoute);
                    }
                })
                setAllowedRoutes(allowedRoutes);
            })
            .catch((error) => {
                console.error(error);
                toast.error(localization.errorLoadingPlugins || "Error loading plugins");
            })
            .finally(() => {
                setLoading(false);
            })
    }, [])

    /*************************************************************************/
    /***************************** RENDER ************************************/
    /*************************************************************************/

    return (
        <LoadingOverlay
            active={loading}
            text={(localization.loading || "Loading") + "..."}>
            <DefaultLayoutHeader>
                <CollapseButton onClick={() => { setCollapsed(!collapsed) }}></CollapseButton>
                <div className="defaultLayoutHeaderRight">
                    <Row>
                        <Col xs={8} sm={8} md={9} lg={10}>
                            <WelcomeDiv>
                                <span className="d-none d-sm-block">{localization.welcome || "Welcome"}, {auth.lastName} {auth.firstName} ({user.nickname})</span>
                                <span className="d-block d-sm-none ">{localization.welcome || "Welcome"}, {auth.lastName} {auth.firstName}</span>
                            </WelcomeDiv>
                        </Col>
                        <Col xs={4} sm={4} md={3} lg={2}>
                            <SettingBtnDiv>
                                <Settings currentView={"defaultLayout"}></Settings>
                            </SettingBtnDiv>
                            <AnnouncementBtnDiv>
                                <Announcements currentView={"defaultLayout"}></Announcements>
                            </AnnouncementBtnDiv>
                        </Col>
                    </Row>
                </div>
            </DefaultLayoutHeader>
            <Sidebar
                collapsed={collapsed}
                allowedRoutes={allowedRoutes}>
            </Sidebar>
        </LoadingOverlay>
    )
}