import { AuthStore, OrbitalStore, SessionStorageStore, CommonUtils } from "orbital_common_components";
import _ from "lodash";

import localization from "@services/localization";
import SpecificAPI from "@services/SpecificAPI";
import * as Utils from "@services/Utils";

export function adminLogin(username, password) {
    return new Promise(function (resolve, reject) {
        SpecificAPI.adminLogin(username, password)
            .then((loginResult) => {
                const { access_token } = loginResult;

                if (_.isEmpty(access_token) === true) {
                    console.error("Access token is missing");
                    reject(0);
                    return;
                }

                SpecificAPI.SBDashboardAuthkey = access_token;
                var p0 = SessionStorageStore.setDashboardAuthKey(access_token);
                var p1 = SessionStorageStore.setUserAuthKey(access_token);
                var p2 = SpecificAPI.getOrbitalConfig();
                return Promise.all([p0, p1, p2, loginResult])
            })
            .then((results) => {
                const orbitalConfig = results[2];
                const loginResult = results[3];

                const auth = {
                    parent: loginResult.parent,
                    privileges: loginResult.privileges,
                    systemUserRole: loginResult.systemUserRole,
                    sbid: loginResult.sbid,
                    role: loginResult.role
                }

                OrbitalStore.setOrbitalConfig(orbitalConfig);

                const lang = CommonUtils.getInitialLocalizationLanguage();
                localization.setLanguage(lang);
                var p0 = SessionStorageStore.setAuth(auth);
                var p1 = SessionStorageStore.setCurrentLang(lang);
                var p2 = AuthStore.setAuth(auth);

                return Promise.all([p0, p1, p2])
            })
            .then(() => {
                resolve();
            })
            .catch((error) => {
                reject({ error });
            })
    })
}

export function orbitalIdLogin(username, password) {
    return new Promise(function (resolve, reject) {
        SpecificAPI.orbitalIdLogin(username, password)
            .then((loginResult) => {
                const { access_token, status, success, message } = loginResult;

                if (status === 200 && success === 1007) {
                    console.error(message);
                    reject(3);
                    return;
                }

                if (status === 200 && success === 1008) {
                    console.error(message);
                    reject(2);
                    return;
                }

                if (_.isEmpty(access_token) === true) {
                    console.error("Access token is missing");
                    reject(0);
                    return;
                }

                SpecificAPI.SBDashboardAuthkey = access_token;
                var p0 = SessionStorageStore.setDashboardAuthKey(access_token);
                var p1 = SessionStorageStore.setUserAuthKey(access_token);
                return Promise.all([p0, p1, loginResult])
            })
            .then((results) => {
                const loginResult = results[2];
                const { uuid: mongoOrbitalId } = loginResult;

                var p0 = SpecificAPI.getMongoOrbitalIdById(mongoOrbitalId);
                var p1 = SpecificAPI.getPostgresOrbitalIdById();
                var p2 = SpecificAPI.getUsersByOrbitalId(mongoOrbitalId);
                var p3 = SpecificAPI.getOrbitalConfig();
                return Promise.all([p0, p1, p2, p3, loginResult])
            })
            .then((results) => {
                const [mongoOrbitalId, postgresOrbitalId, users, orbitalConfig, loginResult] = results;
                const { role } = loginResult;

                if (_.isEmpty(mongoOrbitalId) === true) {
                    reject(4);
                    return;
                }

                mongoOrbitalId._postgresOrbitalId = postgresOrbitalId || {};
                mongoOrbitalId.linkedUsers = users;
                mongoOrbitalId.role = role;
                AuthStore.setAuth(mongoOrbitalId);
                OrbitalStore.setOrbitalConfig(orbitalConfig);

                localization.setLanguage(mongoOrbitalId.lang);
                var p0 = SessionStorageStore.setAuth(mongoOrbitalId);
                var p1 = SessionStorageStore.setCurrentLang(mongoOrbitalId.lang);
                var p2 = AuthStore.setAuth(mongoOrbitalId);

                return Promise.all([p0, p1, p2])
            })
            .then(() => {
                resolve();
            })
            .catch((error) => {
                reject({ error });
            })
    })
}

export function userLogin(user) {
    var brand = user._brand;
    var brandId = brand.id;
    var brandConfig = null;

    return new Promise(function (resolve, reject) {
        var p0 = SpecificAPI.getBrandConfigByBrandId(brandId);
        var p1 = SpecificAPI.getPluginPermissionsByUserId(user.id)
        return Promise.all([p0, p1])
            .then((results) => {
                brandConfig = results[0];
                user._brand._brandConfiguration = brandConfig;

                var data = results[1];
                var groupId = data.groupId;
                if (groupId) {
                    SpecificAPI.getBrandPermissionGroups(brandId)
                        .then((data) => {
                            debugger
                        })
                        .catch((error) => {
                            reject(error);
                        })
                } else {
                    var permission = data.permission;
                    var activityAuthorization = data.activityAuthorization;

                    user._permission = permission;
                    user._activityAuthorization = activityAuthorization;

                    var auth = AuthStore.getAuth();
                    auth.user = user;
                    AuthStore.setAuth(auth);
                    SessionStorageStore.setAuth(auth);

                    resolve();
                }
            })
            .catch((error) => {
                reject(error);
            })
    })
}