import { faClipboard, faCopyright } from "@fortawesome/free-regular-svg-icons";
import { faTachometerAlt, faLanguage, faCogs, faBullhorn, faWrench, faTools, faBox, faUserPlus } from "@fortawesome/free-solid-svg-icons";

import localization from "@services/localization";

import BrandPanel from "./sy_brand_panel";
import SystemLocalizationGlobal from "./sy_localization_global"
import SystemLocalizationPlugins from "./sy_localization_plugins"

import SystemConfiguration from "./sy_system_configuration";
import PluginEditor from "./sy_plugin_editor";
import PluginProfiler from "./sy_plugin_profiler";
import Announcements from "./sy_announcements";
import Support from "./sy_support";
import InstanceManager from "./sy_instance_manager";
import OrbitalManager from "./sy_orbitalid_manager";
import PurchaseManager from "./sy_purchase_manager";
import ProjectManager from "./sy_project_manager";
import AdminManager from "./sy_admin_manager";
import SubApi from "./sy_sub_api";


const systemRoutes = [
  {
    path: "sy_brand_panel",
    exact: true,
    name: localization.sy_brand_panel || "Brand Panel",
    pluginKey: "sy_brand_panel",
    icon: faCopyright,
    element: BrandPanel
  },
  {
    name: localization.sy_system_localization || "Localization",
    icon: faLanguage,
    subRoutes: [
      {
        path: "sy_localization_global",
        exact: true,
        name: localization.sy_localization_global || "Dashboard Localization",
        pluginKey: "sy_localization_global",
        icon: faBox,
        element: SystemLocalizationGlobal
      },
      {
        path: "sy_localization_plugins",
        exact: true,
        name: localization.sy_localization_plugins || "Plugins Localization",
        pluginKey: "sy_localization_plugins",
        icon: faBox,
        element: SystemLocalizationPlugins
      }
    ]
  },
  {
    path: "sy_system_configuration",
    exact: true,
    name: localization.sy_system_configuration || "System Configuration",
    pluginKey: "sy_system_configuration",
    icon: faTools,
    element: SystemConfiguration
  },
  {
    path: "sy_purchase_manager",
    exact: true,
    name: localization.sy_purchase_manager || "Purchase Configuration",
    pluginKey: "sy_purchase_manager",
    icon: faTools,
    element: PurchaseManager
  },
  // {
  //   path: prePath+"/sy_email_customization",
  //   exact: true,
  //   name: localization.sy_email_customization || "Email Customization",
  //   pluginKey: "sy_email_customization",
  //   icon: faEnvelope,
  //   element: EmailCustomization
  // },
  {
    path: "sy_plugin_editor",
    exact: true,
    name: localization.sy_plugin_editor || "Plugin Editor",
    pluginKey: "sy_plugin_editor",
    icon: faCogs,
    element: PluginEditor
  },
  {
    path: "sy_plugin_profiler",
    exact: true,
    name: localization.sy_plugin_profiler || "Plugin Profiler",
    pluginKey: "sy_plugin_profiler",
    icon: faClipboard,
    element: PluginProfiler
  },
  {
    path: "sy_announcements",
    exact: true,
    name: localization.sy_announcements || "Announcements",
    pluginKey: "sy_announcements",
    icon: faBullhorn,
    element: Announcements
  },
  {
    path: "sy_support",
    exact: true,
    name: localization.sy_support || "Support",
    pluginKey: "sy_support",
    icon: faWrench,
    element: Support
  },
  {
    path: "sy_instance_manager",
    exact: true,
    name: localization.sy_instance_manager || "Instance Manager",
    pluginKey: "sy_instance_manager",
    icon: faTachometerAlt,
    element: InstanceManager
  },
  {
    path: "sy_orbitalid_manager",
    exact: true,
    name: localization.sy_orbitalid_manager || "OrbitalId Manager",
    pluginKey: "sy_orbitalid_manager",
    icon: faUserPlus,
    element: OrbitalManager
  },
  {
    path: "sy_project_manager",
    exact: true,
    name: localization.sy_project_manager || "Project Manager",
    pluginKey: "sy_project_manager",
    icon: faWrench,
    element: ProjectManager
  },
  {
    path: "sy_admin_manager",
    exact: true,
    name: localization.sy_admin_manager || "Admin Manager",
    pluginKey: "sy_admin_manager",
    icon: faWrench,
    element: AdminManager
  },
  {
    path: "sy_sub_api",
    exact: true,
    name: localization.sy_sub_api || "Sub API",
    pluginKey: "sy_sub_api",
    icon: faWrench,
    element: SubApi
  }
];
export default systemRoutes;