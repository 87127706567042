import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Card, Form, Button, Modal } from "react-bootstrap";
import { Tooltip } from "orbital_common_components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCog, faUser } from '@fortawesome/free-solid-svg-icons';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { toast } from "react-toastify";
import { Link, useNavigate } from 'react-router-dom';
import { Formik } from "formik";
import * as yup from "yup";

import * as LoginUtilsV2 from "@services/LoginUtilsV2";
import * as Utils from "@services/Utils";
import { AppContext } from "@services/AppContext";
import localization from "@services/localization";

import LoadingOverlay from "@views/newCommonComponents/LoadingOverlay";

import LoginAgreements from "./LoginAgreements";


const capchaValidity = {
    name: "capchaValidation",
    message: localization.capchaIsNotValid || "Capcha is not valid",
    test: (value, schema) => {
        const { capcha } = schema.parent;
        const test = validateCaptcha(capcha);
        return test;
    }
}

const validationSchema = yup.object().shape({
    username: yup.string()
        .typeError(localization.completeField || "Please complete the field")
        .required(localization.completeField || "Please complete the field")
        .email(localization.insertValidEmail || "Insert a valid email address"),
    password: yup.string()
        .typeError(localization.completeField || "Please complete the field")
        .required(localization.completeField || "Please complete the field"),
    capcha: yup.string()
        .typeError(localization.completeField || "Please complete the field")
        .required(localization.completeField || "Please complete the field")
        .test(capchaValidity)
})

export default function Login() {
    const { forceUpdate } = useContext(AppContext);
    const navigate = useNavigate();

    const orbitalLogo = Utils.getStaticOrbitalLogo();
    const pathname = window.location.pathname;
    const containerVersion = process.env.REACT_APP_PLUGIN_VERSION;

    const [loading, setLoading] = useState(false);
    const [agreementType, setAgreementType] = useState(null);

    /*************************************************************************/
    /************************** FUNCTIONS ************************************/
    /*************************************************************************/
    useEffect(() => {
        loadCaptchaEnginge(6);
    }, [])

    /*************************************************************************/
    /************************** FUNCTIONS ************************************/
    /*************************************************************************/
    function switchLoginPanel() {
        const nextPath = pathname === "/login/admin" ? "/login" : "/login/admin";
        navigate(nextPath);
    }

    function login(username, password) {
        username = username ? username.trim() : "";
        password = password ? password.trim() : "";

        setLoading(true);
        const promise = pathname === "/login" ? LoginUtilsV2.orbitalIdLogin(username, password) : LoginUtilsV2.adminLogin(username, password)
        promise
            .then(() => {
                forceUpdate();
                const nextPath = pathname === "/login" ? "/users" : "/dashboardAdmin";
                navigate(nextPath);
            })
            .catch((errorCode) => {
                switch (errorCode) {
                    case 0:
                    case 1:
                        toast.error(localization.errorLogin || "Error during login procedure");
                        break;
                    case 2:
                        toast.warn(localization.identityMailSent || "Identity mail sent");
                        break;
                    case 3:
                        toast.warn(localization.waitToConfirmEmail || "Wait to confirm email");
                        break;
                    case 4:
                        toast.warn(localization.mongoOrbitalIdNotPresent || "Mongo OrbitalId is not present");
                        break;
                    default:
                        toast.error(localization.errorLogin || "Error during login procedure");
                        break;
                }
                console.error(errorCode);
                setLoading(false);
            })
    }

    /*************************************************************************/
    /************************** RENDER ***************************************/
    /*************************************************************************/
    function getTooltipLabel() {
        const tooltip = pathname === "/login/admin" ? (localization.userLogin || "User login") : (localization.adminLogin || "Admin login");
        return tooltip;
    }

    function getLoginIcon() {
        const loginIcon = pathname === "/login/admin" ? faUser : faUserCog;
        return loginIcon;
    }

    function getLoginLabel() {
        if (pathname === "/login/admin") {
            return <div className="system_login_info">  (System login)  </div>
        }
    }

    return (
        <Row className="general_background">
            <Col xs={{ span: 8, offset: 2 }} sm={{ span: 8, offset: 2 }} md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }}>
                <Formik
                    validationSchema={validationSchema}
                    onSubmit={(values, actions) => {
                        login(values.username, values.password)
                    }}
                    validateOnChange={false}
                    initialValues={{
                        username: null,
                        password: null
                    }}>
                    {({ handleSubmit, handleChange, values, isValid, errors, setFieldValue, setValues, validateForm }) => (
                        <React.Fragment>
                            <LoadingOverlay
                                active={loading}
                                text={(localization.loading || "Loading") + "..."}>
                                <Card className="login_card">
                                    <Card.Body>
                                        <Row>
                                            <Col sm={6}>
                                                <Tooltip tooltip={getTooltipLabel()}>
                                                    <FontAwesomeIcon className="login_user_icon"
                                                        icon={getLoginIcon()}
                                                        onClick={switchLoginPanel} />
                                                </Tooltip>
                                            </Col>
                                            <Col sm={6} className="login_card_container_version">
                                                v. {containerVersion}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="login_logo_container">
                                                <img alt="" className="login_logo" src={orbitalLogo} />
                                                {getLoginLabel()}
                                            </Col>
                                        </Row>
                                        <Row className="margin_top_row">
                                            <Col>
                                                <Form.Control
                                                    name={"username"}
                                                    isInvalid={errors.username}
                                                    placeholder={localization.username || "Username"}
                                                    value={values.username || ""}
                                                    onChange={handleChange}>
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <Row className="margin_top_row">
                                            <Col>
                                                <Form.Control
                                                    name={"password"}
                                                    type="password"
                                                    isInvalid={errors.password}
                                                    placeholder={localization.password || "Password"}
                                                    value={values.password || ""}
                                                    onChange={handleChange}>
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <Row className="margin_top_row">
                                            <Col sm={6}>
                                                <LoadCanvasTemplate />
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Control
                                                    name={"capcha"}
                                                    isInvalid={errors.capcha}
                                                    placeholder={localization.capcha || "Capcha"}
                                                    value={values.capcha || ""}
                                                    onChange={handleChange}>
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">{errors.capcha}</Form.Control.Feedback>
                                            </Col>
                                        </Row>
                                        <Row className="margin_top_row">
                                            <Col sm={5}>
                                                <Button onClick={handleSubmit}>
                                                    {localization.login || "Login"}
                                                </Button>
                                            </Col>
                                            <Col sm={2}></Col>
                                            <Col sm={5} className="forgot_password">
                                                <Link to="/forgotPassword">
                                                    {localization.forgotPassword || "Forgot password"}?
                                                </Link>
                                            </Col>
                                        </Row>

                                    </Card.Body>
                                </Card>
                            </LoadingOverlay>
                            <Row>
                                <Col sm={6}>
                                    <div className="loginAgreements" onClick={() => { setAgreementType("termsAndConditions") }}>
                                        {localization.termsAndConditions || "Terms & Conditions"}
                                    </div>
                                </Col>
                                <Col sm={6} style={{ textAlign: "right" }}>
                                    <div className="loginAgreements" onClick={() => { setAgreementType("privacyPolicy") }}>
                                        {localization.privacyPolicy || "Privacy Policy"}
                                    </div>
                                </Col>
                            </Row>
                            <Modal size="lg" show={_.isEmpty(agreementType) === false}>
                                <LoginAgreements
                                    agreementType={agreementType}
                                    onCancel={() => { setAgreementType(null) }}>
                                </LoginAgreements>
                            </Modal>
                        </React.Fragment>
                    )}
                </Formik>
            </Col>
        </Row>
    );
}