import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, Outlet, useLocation,HashRouter } from 'react-router-dom';
import { AuthStore, OrbitalStore, SessionStorageStore, ClientSession } from "orbital_common_components";
import { ToastContainer, toast } from "react-toastify";

import SpecificAPI from "@services/SpecificAPI";
import localization from "@services/localization";
import { AppContext } from "@services/AppContext";

import Login from "@views/login/Login";
import UserList from "@views/dashboard/UserList";

import DashboardUser from "@views/dashboard/DashboardUser";
import DashboardAdmin from "@views/dashboard/DashboardAdmin";

import ForgotPassword from "@views/login/ForgotPassword";
import ResetPassword from "@views/login/ResetPassword";

import PaypalCheckout from "@views/payments/Paypal/PaypalCheckout";
import PaypalCheckoutSuccess from "@views/payments/Paypal/PaypalCheckoutSuccess";
import PaypalCheckoutCancel from "@views/payments/Paypal/PaypalCheckoutCancel";

import StripeCheckout from "@views/payments/Stripe/StripeCheckout";
import StripeCheckoutSuccess from "@views/payments/Stripe/StripeCheckoutSuccess";
import StripeCheckoutCancel from "@views/payments/Stripe/StripeCheckoutCancel";

import MatterportIframe from "@views/plugins/system/sy_matterport_i_frame";

import './css/app.css';

function NoMatch() {
  return (
    <div style={{ padding: 20 }}>
      <h2>404: Page Not Found</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adip.</p>
    </div>
  );
}

export default function App() {
  const [, updateState] = useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [show, setShow] = useState(false);
  const [pluginVersion, setPluginVersion] = useState(null);

  /*************************************************************************/
  /************************ STANDARD ***************************************/
  /*************************************************************************/
  useEffect(() => {
    checkLogin();
    checkLocalization();
  }, []);

  /*************************************************************************/
  /************************** FUNCTIONS ************************************/
  /*************************************************************************/
  function updateLocalizationLanguage(lang) {
    if (_.isEmpty(lang) === false) {
      localization.setLanguage(lang);
      forceUpdate();
    }
  }

  function checkLocalization() {
    const lang = SessionStorageStore.getCurrentLang() || AuthStore.getDefautlLang();
    updateLocalizationLanguage(lang);
  }

  function checkLogin() {
    const isLoggedIn = ClientSession.isLoggedIn_V2();

    if (isLoggedIn === true) {
      const auth = SessionStorageStore.getAuthJson();
      AuthStore.setAuth(auth);

      const dashboardAuthKey = SessionStorageStore.getDashboardAuthKey();
      SpecificAPI.SBDashboardAuthkey = dashboardAuthKey;
      SpecificAPI.getOrbitalConfig()
        .then((data) => {
          OrbitalStore.setOrbitalConfig(data);
        })
        .finally(() => {
          setShow(true);
        })
    } else {
      setShow(true);
    }
  }

  /*************************************************************************/
  /************************** RENDER ***************************************/
  /*************************************************************************/
  function LoggedRedirect() {
    const location = useLocation();
    const auth = SessionStorageStore.getAuthJson();
    const { systemUserRole, user } = auth;
    if (systemUserRole === "root" || systemUserRole === "admin") {
      return <Navigate to={{ pathname: "/dashboardAdmin", state: { from: location } }} />
    } else {
      if (_.isEmpty(user) === true) {
        return <Navigate to={{ pathname: "/users", state: { from: location } }} />
      } else {
        return <Navigate to={{ pathname: "/dashboardUser", state: { from: location } }} />
      }
    }
  }

  function HomeRoute({ children }) {
    const isLoggedIn = ClientSession.isLoggedIn_V2();
    if (isLoggedIn === false) {
      const location = useLocation();
      return <Navigate to={{ pathname: "/login", state: { from: location } }} />
    } else if (isLoggedIn === true && _.isEmpty(children) === false) {
      return children;
    } else if (isLoggedIn === true && _.isEmpty(children) === true) {
      return LoggedRedirect();
    }
  }

  function LoginRoute({ children }) {
    const isLoggedIn = ClientSession.isLoggedIn_V2();
    if (isLoggedIn === false) {
      return children;
    } else {
      return LoggedRedirect()
    }
  }

  return (
    <AppContext.Provider value={{ pluginVersion, setPluginVersion, forceUpdate, updateLocalizationLanguage }}>
      <React.Fragment>
        <ToastContainer
          position={"top-center"}
          closeButton={false}
          draggable={false}
          pauseOnHover={false}
          autoClose={3000}
          newestOnTop={true}
          theme="colored" />
        {show == true &&
          <Routes>
            <Route path="login" name="login" element={<LoginRoute><Login /></LoginRoute>} />
            <Route path="login/admin" name="login" element={<LoginRoute><Login /></LoginRoute>} />

            <Route path="/" element={<HomeRoute />} />
            <Route path="dashboardUser/*" name="dashboardUser" element={<HomeRoute><DashboardUser /><Outlet /></HomeRoute>} />
            <Route path="dashboardAdmin/*" name="dashboardAdmin" element={<HomeRoute><DashboardAdmin /><Outlet /></HomeRoute>} />

            <Route exact path="/users" name="users" element={<HomeRoute ><UserList /></HomeRoute>} />

            <Route exact path="/forgotPassword" name="forgotPassword" element={<ForgotPassword />} />
            <Route exact path="/resetPassword" name="resetPassword" element={<ResetPassword />} />

            <Route exact path="/paypalCheckout" name="paypalCheckout" element={<PaypalCheckout />} />
            <Route exact path="/paypalCheckoutSuccess" name="paypalCheckoutSuccess" element={<PaypalCheckoutSuccess />} />
            <Route exact path="/paypalCheckoutCancel" name="paypalCheckoutCancel" element={<PaypalCheckoutCancel />} />

            <Route exact path="/stripeCheckout" name="stripeCheckout" element={<StripeCheckout />} />
            <Route exact path="/stripeCheckoutSuccess" name="stripeCheckoutSuccess" element={<StripeCheckoutSuccess />} />
            <Route exact path="/stripeCheckoutCancel" name="stripeCheckoutCancel" element={<StripeCheckoutCancel />} />

            <Route exact path="/matterport/preview/:spaceId/:lang" name="Matterport Preview" element={<MatterportIframe />} />
            <Route exact path="/matterport/preview/:spaceId/:lang/:mpConfig" name="Matterport Preview" element={<MatterportIframe />} />
            <Route path="/#/matterport/preview/:spaceId/:lang" name="Matterport Preview" element={<MatterportIframe />} /> 
            <Route path="/#/matterport/preview/:spaceId/:lang/:mpConfig" name="Matterport Preview" element={<MatterportIframe />} />  

            {/* <Route path="/matterport">
              <HashRouter>
                <Route path="/preview/:spaceId/:lang/:mpConfig" name="Matterport Preview" element={<MatterportIframe />} />
              </HashRouter>
            </Route> */}
                       {/* for backward client compatability */}
            {/* <Route exact path="/tour/:customUrl" name="Matterport Preview" element={<MatterportIframe />} /> */}

            {/* <Route exact path="/deleteObsoleteOrbitalId" name="DeleteObsoleteOrbitalId" element={<DeleteObsoleteOrbitalId />} /> */}


            <Route path="*" element={<NoMatch />} />



            {/* <Route exact path='/login' element={<LoginRoute />}>
              <Route exact path='/login' element={<Login />} />
              <Route exact path="/login/admin" element={<Login />} />
            </Route> */}

            {/* <Route path="/" element={<HomeRoute />} >
              <Route index path="/" element={admin ? <DashboardAdmin /> : <DashboardUser />} />
              <Route path="dashboardUser/*" name="dashboardUser" element={<><DashboardUser /><Outlet /></>} />
              <Route path="dashboardAdmin/*" name="dashboardAdmin" element={<><DashboardAdmin /><Outlet /></>} />
            </Route> */}

            {/* <Route path='/users' element={<HomeRoute />}>
              <Route path="/users" name="users" element={<UserList />} />
            </Route> */}
          </Routes>
        }

      </React.Fragment>
    </AppContext.Provider>
  )
}